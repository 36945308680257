var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-page-header',{attrs:{"title":'Services',"subtitle":`Menu`,"section":_vm.menu.from_date},scopedSlots:_vm._u([{key:"section",fn:function(){return [_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.menu.from_date, "dddd do, m, y"))+" ")]),_c('i',{staticClass:"el-icon-right mx-1"}),_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.menu.to_date, "dddd do, m, y"))+" ")]),(_vm.menu.id === 172)?_c('el-tag',{attrs:{"type":_vm.setType('current')}},[_vm._v(" Current ")]):_vm._e()],1)]},proxy:true}])}),(_vm.loading)?_c('eden-loader'):[_c('eden-table-actions',{staticClass:"menu-actions",attrs:{"title":_vm.title,"positionSearchAtEnd":""},on:{"search":_vm.searchItems}},[_c('template',{slot:"actions"},[_c('div',{staticClass:"page--head-actions is-flex is-flex-wrap align-center"},[(_vm.allowAccessFor(['superadmin', 'admin', 'operations']))?_c('el-dropdown',{attrs:{"placement":'bottom'},on:{"command":_vm.command}},[_c('el-button',{attrs:{"type":"plain"}},[_vm._v(" Actions "),_c('i',{staticClass:"eden-icon-arrow-down"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"eden-icon-edit","command":{
                  action: 'edit',
                }}},[_vm._v("Edit menu")]),_c('el-dropdown-item',{attrs:{"icon":"el-icon-link","command":{
                  action: 'duplicate',
                }}},[_vm._v("Duplicate menu")]),_c('el-dropdown-item',{attrs:{"icon":"eden-icon-delete","command":{
                  action: 'delete',
                }}},[_vm._v("Delete menu")])],1)],1):_vm._e()],1)])],2),_vm._l((_vm.mealItems),function(meal,index){return _c('el-row',{key:index,attrs:{"type":"flex"}},[_c('el-col',{attrs:{"md":24}},[_c('div',{staticClass:"card mb-10"},[_c('div',{staticClass:"header mb-10"},[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(meal?.mealItem?.name))])]),(meal.combos?.length)?_c('el-row',{staticClass:"flex-wrap is-align-center font-sm justify-between",attrs:{"type":"flex"}},_vm._l((meal?.combos),function(combo,i){return _c('el-col',{key:i,attrs:{"md":12}},[_c('div',{staticClass:"combo-card is-flex is-align-center",class:{
                  'w-120': combo?.combo == meal?.mealItem?.name,
                  'no-image': !combo.image,
                }},[_c('div',{staticClass:"eden-meal--image"},[(combo.image != null)?_c('img',{attrs:{"src":_vm.getThumbnailFromCloudinary(combo.image),"alt":"Image"}}):_vm._e()]),_c('div',{staticClass:"additional-info"},[_c('div',{staticClass:"text-bold mr-10"},[_vm._v(" "+_vm._s(_vm.formatText(combo.combo))+" ")]),_vm._l((combo.tags_id),function(tag){return _c('div',{key:tag.id},[_vm._v(" "+_vm._s(_vm.mapFoodTag(tag)?.name)+" ")])}),(combo.tags_id?.length === 0 || !combo.tags_id)?_c('div',[_vm._v(" No tags added ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(combo.calorie ? combo.calorie + " cals" : "No calories added")+" ")])],2)])])}),1):_vm._e()],1)])],1)})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }