<template>
  <div>
    <eden-page-header
      :title="'Services'"
      :subtitle="`Menu`"
      :section="menu.from_date"
    >
      <template #section>
        <div>
          <span>
            {{ formatDate(menu.from_date, "dddd do, m, y") }}
          </span>
          <i class="el-icon-right mx-1"></i>
          <span>
            {{ formatDate(menu.to_date, "dddd do, m, y") }}
          </span>
          <el-tag v-if="menu.id === 172" :type="setType('current')">
            Current
          </el-tag>
        </div>
      </template>
    </eden-page-header>

    <eden-loader v-if="loading" />
    <template v-else>
      <eden-table-actions
        :title="title"
        positionSearchAtEnd
        @search="searchItems"
        class="menu-actions"
      >
        <template slot="actions">
          <div class="page--head-actions is-flex is-flex-wrap align-center">
            <el-dropdown
              v-if="allowAccessFor(['superadmin', 'admin', 'operations'])"
              :placement="'bottom'"
              @command="command"
            >
              <el-button type="plain">
                Actions
                <i class="eden-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="eden-icon-edit"
                  :command="{
                    action: 'edit',
                  }"
                  >Edit menu</el-dropdown-item
                >
                <el-dropdown-item
                  icon="el-icon-link"
                  :command="{
                    action: 'duplicate',
                  }"
                  >Duplicate menu</el-dropdown-item
                >

                <el-dropdown-item
                  icon="eden-icon-delete"
                  :command="{
                    action: 'delete',
                  }"
                  >Delete menu</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </eden-table-actions>

      <el-row v-for="(meal, index) in mealItems" :key="index" type="flex">
        <el-col :md="24">
          <div class="card mb-10">
            <div class="header mb-10">
              <span class="text-bold">{{ meal?.mealItem?.name }}</span>
            </div>
            <el-row
              type="flex"
              class="flex-wrap is-align-center font-sm justify-between"
              v-if="meal.combos?.length"
            >
              <el-col :md="12" v-for="(combo, i) in meal?.combos" :key="i">
                <div
                  class="combo-card is-flex is-align-center"
                  :class="{
                    'w-120': combo?.combo == meal?.mealItem?.name,
                    'no-image': !combo.image,
                  }"
                >
                  <!-- <el-checkbox v-if="combo.combo != meal?.name" class="mr-1" /> -->
                  <div class="eden-meal--image">
                    <img
                      v-if="combo.image != null"
                      :src="getThumbnailFromCloudinary(combo.image)"
                      alt="Image"
                    />
                  </div>
                  <div class="additional-info">
                    <div class="text-bold mr-10">
                      {{ formatText(combo.combo) }}
                    </div>
                    <div v-for="tag in combo.tags_id" :key="tag.id">
                      {{ mapFoodTag(tag)?.name }}
                    </div>
                    <div v-if="combo.tags_id?.length === 0 || !combo.tags_id">
                      No tags added
                    </div>
                    <div>
                      {{
                        combo.calorie
                          ? combo.calorie + " cals"
                          : "No calories added"
                      }}
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import menu from "@/requests/services/meal/menu";
import * as actions from "@/store/action-types";
export default {
  name: "ViewMenu",
  props: {
    serviceTypeId: {
      type: [String, Number],
      default: "",
    },
    serviceType: {
      type: [Object, String],
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      menu: {},
      search: "",
    };
  },
  computed: {
    title() {
      return `Menu for ${this.formatDate(
        this.menu?.from_date,
        "dddd do, m, y",
      )} - ${this.formatDate(this.menu?.to_date, "dddd do, m, y")}`;
    },
    storedMealItems() {
      return this.$store.getters.meal_item_list.filter(
        (item) => item.meal_type === "main",
      );
    },
    mealItems() {
      const res = this.menu.menu_items_service_type_id?.map((item) => {
        let combos;

        if (
          item?.class_category != null &&
          item?.class_category.includes("juices")
        ) {
          combos = [
            { id: item.id, ...item, combo: item?.name, image: item.image_url },
          ];
        }

        const mealCombos = this.menu.visible_combos.find(
          (it) => it.main_item_service_type_id === item,
        )?.combos;
console.log(mealCombos, "mealCOmbo")
        combos = mealCombos?.map((combo) => {
          let availableSides = Object.keys(combo).filter((item) =>
            item.includes("sides_name"),
          );
          if (!availableSides.length) {
            return [];
          } else {
            let sideItem = "";
            availableSides?.map((side) => {
              if (combo[side]) sideItem += ", " + this.formatText(combo[side]);
            });
            return {
              ...combo,
              combo: sideItem.slice(1),
              image: combo.image,
              checked: true,
              meal_id: item.id,
            };
          }
        });

        return {
          id: item,
          mealItem: this.storedMealItems?.find((it) => it.id === item),
          combos,
        };
      });

      return this.search.length != 0
        ? res.filter((item) =>
            item.mealItem?.name
              ?.toLowerCase()
              .includes(this.search.toLowerCase()),
          )
        : res;
    },

    food_tags() {
      return this.$store.getters.food_tags;
    },
  },
  mounted() {
    this.getMenu();
  },
  methods: {
    searchItems(v) {
      console.log(this.mealItems);
      this.search = v;
    },
    editMenu() {
      this.$route.params.service = "menu";
      this.$router.push({
        name: "services.service-type.edit",
        params: { id: this.serviceTypeId, service: "menu" },
      });
    },
    deleteMenu() {
      this.loading = true;
      menu
        .delete({ menu_id: Number(this.serviceTypeId) })
        .then((response) => {
          this.$message.success(response.data.message);
          this.loading = false;

          this.$router.replace({
            name: "services.service",
            params: { service: "meal" },
            query: { tab: "menu" },
          });
        })
        .catch((error) => {
          this.loading = false;

          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    duplicateMenu() {
      this.loading = true;
      menu
        .duplicate(this.serviceTypeId)
        .then((response) => {
          this.$message.success(response.data.message);
          this.loading = false;
          this.$router.replace({
            name: "services.service",
            params: { service: "meal" },
            query: { tab: "menu" },
          });
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    command(command) {
      this.menuId = parseInt(command.id);
      switch (command.action) {
        case "view":
          // this.menu(command.id, "menu");
          break;
        case "edit":
          this.editMenu();
          break;
        case "duplicate":
          this.duplicateMenu();
          break;
        case "delete":
          this.deleteMenu();
          break;
      }
    },
    async getMenu() {
      this.loading = true;

      menu
        .get(this.serviceTypeId)
        .then(async (res) => {
          const { data, status } = res.data;
          console.log(res.data);
          if (status) {
            await this.getMealItems();
            this.menu = data;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    async getMealItems() {
      if (this.storedMealItems.length == 0) {
        await this.$store.dispatch(actions.GET_MEAL_ITEM_LIST);
      }
      if (this.food_tags.length === 0) {
        await this.$store.dispatch(actions.GET_FOOD_TAGS);
      }
      // this.$store.dispatch(actions.GET_MENU_TYPES);
    },
    mapFoodTag(tag_id) {
      return this.food_tags.find((item) => item.id === tag_id);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 3% 4%;
  background-color: var(--eden-grey-octonary);
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.eden-meal--image {
  height: 36px;
  width: 36px;
  min-height: 36px;
  min-width: 36px;
  border-radius: 100%;
  background: #798b83;
  margin-right: 20px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 100%;
    background: #798b83;
  }

  + div {
    width: 100%;
  }
}

.combo-card {
  background-color: var(--white);
  padding: 8px;
  border-radius: 8px;
  width: 95%;
  justify-content: space-between;
  margin-bottom: 20px;

  &.no-image {
    border: 1px solid var(--eden-red-quinary);
  }
}

.additional-info {
  line-height: 18px;
}

.w-120 {
  width: 120%;
}

.flex-wrap {
  flex-wrap: wrap;
}
</style>
